export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/test/Home.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
]
