import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // (重寄)認證信
    resendEmail() {
      return new Promise((resolve, reject) => {
        axios('/user/email/resend', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // (重刷)側欄
    resetSidebar() {
      return new Promise((resolve, reject) => {
        axios('/user/sideBar/check', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // (登出)
    userLogout() {
      return new Promise((resolve, reject) => {
        axios('/user/logout', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // (查詢)使用者
    getUserSearch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/user/search', { headers: { token: useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)路由
    getUserRoute(ctx, routerData) {
      return new Promise((resolve, reject) => {
        axios('/user/router/check', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
          data: {
            ...routerData,
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // (查詢)使用者資料
    getUserInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/user/${queryParams.userId}/info`, { headers: { token: useJwt.getToken() }, params: queryParams.params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)變更紀錄
    getUserChangeLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/user/${queryParams.userId}/change/log`, { headers: { token: useJwt.getToken() }, params: queryParams.params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)網域
    getDomain(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/domain', { headers: { token: useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (生成)圖形驗證
    getImgCaptcha(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios('/client/captcha/get', {
          method: 'GET',
          headers: {
            token: useJwt.getToken(),
          },
          params: queryParams,
          responseType: 'blob',
        })

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
